import http from '../httpConfig';

const server = () => {
  const getTime = () => new Promise((resolve, reject) => {
    const url = '/server/date_time';

    http.get(url)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err.response.data));
  });

  return {
    getTime,
  };
};

export default server;
