<template>
  <article class="appointment-scheduling">
    <scheduling-header />

    <transition
      name="fade"
      mode="out-in"
    >
      <progress-tracker v-if="isProgressTracker" />
    </transition>

    <transition
      name="fade"
      mode="out-in"
    >
      <top-bar v-if="isTopBar" />
    </transition>

    <transition
      name="fade"
      mode="out-in"
      @before-leave="beforeLeave"
      @enter="enter"
      @after-enter="afterEnter"
    >
      <router-view />
    </transition>

    <the-footer
      :class="{ 'landing-page__footer--invisible': partner.hasIframe }"
      class="landing-page__footer--inner"
    />
  </article>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import setPatient from '@/mixins/setPatient';
import TheFooter from '@/components/layout/Footer.vue';
import TopBar from '@/components/appointmentScheduling/TopBar.vue';
import SchedulingHeader from '@/components/appointmentScheduling/Header.vue';
import ProgressTracker from '@/components/appointmentScheduling/ProgressTracker.vue';

export default {
  name: 'AppointmentScheduling',

  mixins: [setPatient],

  components: {
    SchedulingHeader,
    ProgressTracker,
    TheFooter,
    TopBar,
  },

  data() {
    return {
      prevHeight: 0,
    };
  },

  computed: {
    ...mapGetters('ui', ['progressTracker']),
    ...mapGetters('partner', ['partner', 'medicalSpecialties']),
    ...mapGetters('user', ['logged']),

    isProgressTracker() {
      return this.$route.name !== 'AppointmentSchedulingSummary';
    },

    isTopBar() {
      return this.progressTracker
        .some((step) => (step.step === 2 || step.step === 3) && step.isCurrent);
    },
  },

  watch: {
    '$route.name': {
      deep: false,
      immediate: true,
      handler(route) {
        this.resetProgressTrackerStep();

        if (this.progressTracker.some((item) => item.route.unlogged === route)) {
          const { step } = this.progressTracker.find((item) => item.route.unlogged === route);

          this.setCurrentProgressTrackerStep(step);
        }
      },

      medicalSpecialties: {
        deep: true,
        immediate: true,
        handler(value) {
          if (value.length === 0) {
            this.fetchMedicalSpecialties();
          }
        },
      },
    },
  },

  created() {
    if (document.domain === 'paciente.drconecta.com.br') {
      window.fbq('init', '532918017596757');
    }

    if (this.logged) {
      this.$router.push({ name: 'UserAccount' });
    }
  },

  methods: {
    ...mapActions('ui', ['setCurrentProgressTrackerStep', 'resetProgressTrackerStep']),

    beforeLeave(element) {
      this.prevHeight = getComputedStyle(element).height;
    },

    enter(element) {
      const { height } = getComputedStyle(element);
      element.style.height = height;
      setTimeout(() => { element.style.height = height; });
    },

    afterEnter(element) {
      element.style.height = 'auto';
    },
  },
};
</script>
