<template>
  <section class="progress-tracker">
    <ol class="progress-tracker__steps">
      <li
        v-for="(step, index) in progressTracker"
        :key="`progressTracker-step-${index}`"
        :class="[
          ...setStepClasses(step.step),
          { 'progress-tracker__item--disabled': !isStepClickable(step.step) }
        ]"
        class="progress-tracker__item"
      >
        <div class="progress-tracker__item-wrapper">
          <div
            :class="[
              { 'progress-tracker__icon--complete': step.isCompleted },
              { 'progress-tracker__icon--current': step.isCurrent }
            ]"
            class="progress-tracker__icon"
          >
            <i
              v-if="step.isCompleted"
              class="progress-tracker__icon-check icon icon-check"
            />

            <span
              v-else
              :class="{ 'progress-tracker__icon-circle--current': step.isCurrent }"
              class="progress-tracker__icon-circle"
            />
          </div>

          <div
            :class="[
              { 'progress-tracker__title--link': step.isCompleted },
              { 'progress-tracker__title--current': step.isCurrent }
            ]"
            class="progress-tracker__title"
            @click.prevent="go(step)"
          >
            {{ step.title }}
          </div>
        </div>
      </li>
    </ol>

    <transition
      name="fade"
      mode="out-in"
    >
      <timer
        v-if="$route.name === 'UserSchedulingCheckout'
          || $route.name === 'AppointmentSchedulingCheckout'"
      />
    </transition>
  </section>
</template>

<script>
import { mapGetters } from 'vuex';
import Timer from '@/components/ui/Timer.vue';

export default {
  name: 'ProgressTracker',

  components: {
    Timer,
  },

  computed: {
    ...mapGetters('ui', ['progressTracker']),
    ...mapGetters('user', ['logged']),
  },

  methods: {
    setStepClasses(step) {
      const current = this.progressTracker.find((item) => item.step === step);
      const classes = [];

      if (step < this.progressTracker.length) {
        const next = this.progressTracker.find((item) => item.step === step + 1);

        if (next.isCompleted) {
          classes.push('progress-tracker__item--afterIsCompleted');
        }

        if (next.isCurrent && !next.isCompleted) {
          classes.push('progress-tracker__item--afterIsCurrent');
        }
      }

      if (step > 1) {
        if (current.isCompleted) {
          classes.push('progress-tracker__item--beforeIsCompleted');
        }

        if (current.isCurrent && !current.isCompleted) {
          classes.push('progress-tracker__item--beforeIsCurrent');
        }
      }

      return classes;
    },

    isStepClickable(step) {
      if (step === 1) {
        return true;
      }

      if (this.progressTracker.find((item) => item.step === step - 1).isCompleted) {
        return true;
      }

      return false;
    },

    go(step) {
      const routeType = this.logged ? 'logged' : 'unlogged';

      if (this.$route.name !== step.route[routeType]) {
        this.$router.push({ name: step.route[routeType] });
      }
    },
  },
};
</script>
