var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"progress-tracker"},[_c('ol',{staticClass:"progress-tracker__steps"},_vm._l((_vm.progressTracker),function(step,index){return _c('li',{key:("progressTracker-step-" + index),staticClass:"progress-tracker__item",class:_vm.setStepClasses(step.step).concat( [{ 'progress-tracker__item--disabled': !_vm.isStepClickable(step.step) }]
      )},[_c('div',{staticClass:"progress-tracker__item-wrapper"},[_c('div',{staticClass:"progress-tracker__icon",class:[
            { 'progress-tracker__icon--complete': step.isCompleted },
            { 'progress-tracker__icon--current': step.isCurrent }
          ]},[(step.isCompleted)?_c('i',{staticClass:"progress-tracker__icon-check icon icon-check"}):_c('span',{staticClass:"progress-tracker__icon-circle",class:{ 'progress-tracker__icon-circle--current': step.isCurrent }})]),_c('div',{staticClass:"progress-tracker__title",class:[
            { 'progress-tracker__title--link': step.isCompleted },
            { 'progress-tracker__title--current': step.isCurrent }
          ],on:{"click":function($event){$event.preventDefault();return _vm.go(step)}}},[_vm._v(" "+_vm._s(step.title)+" ")])])])}),0),_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[(_vm.$route.name === 'UserSchedulingCheckout'
        || _vm.$route.name === 'AppointmentSchedulingCheckout')?_c('timer'):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }